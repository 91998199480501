import React from 'react';
import { bindings, hook } from '@vl/redata';
import { useFormik } from 'formik';
import AssetModelFormatter from '@uz/unitz-models/AssetModel/formatter';
import useRoute from '@vl/hooks/useGbRouteDe';
import { getClient, gql } from '@vl/mod-clients/hasuraApp';
import _ from 'lodash';
import { v4 as getUUid } from 'uuid';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            create_form: hook((ctx) => {
              const Yup = ctx.get('ValidateHandler.Yup');
              const account_id = ctx.apply('accountModel.getAccountId');
              const user_id = ctx.apply('currentUserModel.getUserId');
              const route = useRoute();
              const form = useFormik({
                initialValues: {
                  user_id,
                  name: '',
                  description: '',
                  lessons: [
                    {
                      id: getUUid(),
                      course_template_id: '',
                      name: '',
                      photo_url: '',
                      description: '',
                    },
                  ],
                },
                onSubmit: async (values, actions) => {
                  try {
                    const object = {
                      user_id,
                      account_id,
                      name: values.name,
                      description: values.description,
                    };
                    const client = await getClient();
                    const template_data = await client.request(
                      gql`
                        mutation CreateProgramTemplate($object: b2b_program_template_insert_input = {}) {
                          insert_b2b_program_template_one(object: $object) {
                            id
                          }
                        }
                      `,
                      { object: object }
                    );

                    const program_template_id = _.get(template_data, 'insert_b2b_program_template_one.id', '');

                    if (!!template_data) {
                      const objects = _.map(form.values.lessons, (lesson) => {
                        return {
                          ..._.omit(lesson, ['id']),
                          user_id: user_id,
                          template_id: program_template_id,
                          account_id: account_id,
                        };
                      });

                      const template_lessons_data = await client.request(
                        gql`
                          mutation AddLessonToProgramTemplate(
                            $objects: [b2b_program_template_lesson_insert_input!] = {}
                          ) {
                            insert_b2b_program_template_lesson(objects: $objects) {
                              affected_rows
                              returning {
                                id
                                name
                              }
                            }
                          }
                        `,
                        { objects: objects }
                      );

                      if (!!template_lessons_data)
                        route.navigate(
                          ctx.apply('routeStore.toUrl', 'toolAccountProgramTemplateCreated', {
                            id: program_template_id,
                          })
                        );
                    }
                  } catch (err) {
                    console.log(err);
                  }
                },
                validationSchema: Yup.object().shape({
                  name: Yup.string().required(ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                  description: Yup.string().required(ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                  lessons: Yup.array().of(Yup.object().shape({})),
                }),
              });

              _.assign(form, {
                canSubmit: form.isValid && form.dirty,
                array: {
                  add: (field) => {
                    const fieldValue = _.get(form, `values.${field}`);
                    const fieldArray = _.castArray(fieldValue);
                    const valueToInsert = _.clone(_.last(fieldArray));
                    const cleanItem = (val) => {
                      let rtn = '';
                      if (_.isPlainObject(val)) {
                        rtn = _.clone(val);
                        for (const key in valueToInsert) {
                          rtn[key] = cleanItem(valueToInsert[key]);
                        }
                      } else if (_.isArray(val)) {
                        rtn = [];
                      }
                      return rtn;
                    };

                    let newItemVal = cleanItem(valueToInsert);
                    if (_.isEmpty(valueToInsert)) {
                      newItemVal = {
                        course_template_id: '',
                        id: getUUid(),
                        name: '',
                        photo_url: '',
                        description: '',
                      };
                    } else {
                      newItemVal.id = valueToInsert.id + 1;
                    }
                    form.setFieldValue(field, [...fieldArray, newItemVal]);
                  },

                  remove: (field, index) => {
                    const fieldValue = _.get(form, `values.${field}`);
                    const fieldArray = _.cloneDeep(_.castArray(fieldValue));
                    fieldArray.splice(index, 1);
                    const newValue = [...fieldArray];
                    form.setFieldValue(field, newValue);
                  },

                  reset: (field) => {
                    form.setFieldValue(`${field}.course_template_id`, '');
                    form.setFieldValue(`${field}.name`, '');
                    form.setFieldValue(`${field}.photo_url`, '');
                    form.setFieldValue(`${field}.description`, '');
                  },
                },
                getUploaderProps: AssetModelFormatter.getUploaderProps(ctx, form),
              });

              return form;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;

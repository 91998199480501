import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { UnAuthRedirect } from '@uz/unitz-tool-components/AppRedirect';
import FormCreateProgramTemplate from '@uz/unitz-tool-components/FormCreateProgramTemplate';

const Index = () => {
  // @TODO: validate missing required input
  return (
    <DIV className="pageContainer">
      <UnAuthRedirect to={ctx.apply('authModel.generateLoginRedirectUrl')}>
        <div className="p-4">
          <div className="mb-3 text-main uppercase font-semibold text-base">
            {ctx.apply('i18n.t', 'ZoomToolProgramTemplate.add_form_title')}
          </div>
          <div className="p-4 bg-white500 rounded-lg shadow-md">
            <FormCreateProgramTemplate />
          </div>
        </div>
      </UnAuthRedirect>
    </DIV>
  );
};

export default displayName(Index);
